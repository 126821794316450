
              @import "@/assets/css/variables.scss";
            

















































































































// @import '@/assets/css/pages/financeInfo.scss';
.add_bank {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
}
.page_panel_inner {
  min-height: 650px;
  .panel_title {
    font-size: 16px;
    color: #09090d;
    font-weight: 700;
    padding-bottom: 16px;
  }
  .el-empty {
    height: 100%;
  }
}
.add_bank_popover {
  .el-button {
    width: 100%;
    border: none;
  }
}
.empty_desc {
  .desc_title {
    color: $text-primary;
    font-weight: 700;
    padding-bottom: 16px;
  }
  .desc_text {
    .special_span {
      color: $text-link;
    }
  }
}
.panel_card_list {
  .panel_card_item {
    margin-bottom: 16px;
  }
}
