
              @import "@/assets/css/variables.scss";
            





























































































































































































































































.add_bank_drawer_form {
  .el-form-item {
    .el-select {
      width: 100%;
    }
  }

  .special_select_form_item {
    .el-form-item__content {
      select {
        width: 48%;
        height: 32px;
        padding: 0 15px;
        font-size: 14px;
        color: rgb(98, 109, 113);
        margin-bottom: 5px;
        border-style: solid;
        border-width: $border-width;
        border-color: $border-color;
        border-radius: 6px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        @include rtl-sass-value(background,
          $white url(~@/assets/images-1/down.png) no-repeat 90% center,
          $white url(~@/assets/images-1/down.png) no-repeat 10% center);

        &:nth-child(-n + 1) {
          @include rtl-sass-prop(margin-right, margin-left, calc((100% - 48% * 2)));
        }

        &:nth-child(2) {
          @include rtl-sass-value(float, right, left);
        }

        /deep/ option {
          border-radius: 8px;
          background-color: $white;
          box-shadow: 0px 0px 14.72px 1.28px rgba(11, 16, 122, 0.09);
          font-size: 16px;
          line-height: 54px;
          color: $black;
        }
      }

      .error {
        select {
          border: 1px solid $light-red !important;
        }
      }
    }
  }

  /deep/ .form_upload {
    .el-upload {
      padding: 0;
      border: none;
      background-color: transparent;
      display: flex;
    }

    .el-upload__tip {
      padding-top: 8px;
    }
  }
}
